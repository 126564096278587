<template>
  <div class="relative">
    <div
      style="z-index: 3"
      @click="menuOpen = !menuOpen"
      v-if="menuOpen"
      class="fixed top-0 bottom-0 left-0 right-0"
    ></div>
    <div>
      <button
        @click.prevent="menuOpen = !menuOpen"
        type="button"
        :class="{
          'text-gray-900': menuOpen,
          'text-gray-500': !menuOpen
        }"
        class="group inline-flex items-center space-x-2 text-base leading-6 font-semibold hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150 text-gray-500"
      >
        <span style="z-index: 0">{{ label }}</span>
        <svg
          x-state:on="Item active"
          x-state:off="Item inactive"
          class="h-5 w-5 group-hover:text-green-700 group-focus:text-green-600 transition ease-in-out duration-150 text-green-500"
          x-bind:class="{ 'text-green-600': flyoutMenuOpen, 'text-green-500': !flyoutMenuOpen }"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>
      <transition name="fade">
        <div
          v-show="menuOpen"
          class="absolute -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
          style="z-index: 3"
        >
          <div class="rounded-lg shadow-lg">
            <div class="rounded-lg shadow-xs overflow-hidden">
              <div
                class="z-20 relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8"
              >
                <slot name="mainLinks"></slot>
                <!-- <a
                href="#"
                class="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
              >
                <svg
                  class="flex-shrink-0 h-6 w-6 text-indigo-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                  ></path>
                </svg>
                <div class="space-y-1">
                  <p class="text-base leading-6 font-medium text-gray-900">
                    Sample Link
                  </p>
                  <p class="text-sm leading-5 text-gray-500">
                    Get a better understanding of where your traffic is coming
                    from.
                  </p>
                </div>
              </a>
              <a
                href="#"
                class="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
              >
                <svg
                  class="flex-shrink-0 h-6 w-6 text-indigo-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                  ></path>
                </svg>
                <div class="space-y-1">
                  <p class="text-base leading-6 font-medium text-gray-900">
                    Sample Link
                  </p>
                  <p class="text-sm leading-5 text-gray-500">
                    Speak directly to your customers in a more meaningful way.
                  </p>
                </div>
              </a>
              <a
                href="#"
                class="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
              >
                <svg
                  class="flex-shrink-0 h-6 w-6 text-indigo-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                  ></path>
                </svg>
                <div class="space-y-1">
                  <p class="text-base leading-6 font-medium text-gray-900">
                    Sample Link
                  </p>
                  <p class="text-sm leading-5 text-gray-500">
                    Your customers data will be safe and secure.
                  </p>
                </div>
              </a>
              <a
                href="#"
                class="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
              >
                <svg
                  class="flex-shrink-0 h-6 w-6 text-indigo-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                  ></path>
                </svg>
                <div class="space-y-1">
                  <p class="text-base leading-6 font-medium text-gray-900">
                    Sample Link
                  </p>
                  <p class="text-sm leading-5 text-gray-500">
                    Connect with third-party tools that you’re already using.
                  </p>
                </div>
              </a>
              <a
                href="#"
                class="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
              >
                <svg
                  class="flex-shrink-0 h-6 w-6 text-indigo-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                  ></path>
                </svg>
                <div class="space-y-1">
                  <p class="text-base leading-6 font-medium text-gray-900">
                    Sample Link
                  </p>
                  <p class="text-sm leading-5 text-gray-500">
                    Build strategic funnels that will drive your customers to
                    convert
                  </p>
                </div>
              </a> -->
              </div>
              <div
                class="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8"
              >
                <slot name="bottomLinks"></slot>
                <!-- <div class="flow-root">
                <a
                  href="#"
                  class="-m-3 p-3 flex items-center space-x-3 rounded-md text-base leading-6 font-medium text-gray-900 hover:bg-gray-100 transition ease-in-out duration-150"
                >
                  <svg
                    class="flex-shrink-0 h-6 w-6 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                    ></path>
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <span> Sample Link</span>
                </a>
              </div>
              <div class="flow-root">
                <a
                  href="#"
                  class="-m-3 p-3 flex items-center space-x-3 rounded-md text-base leading-6 font-medium text-gray-900 hover:bg-gray-100 transition ease-in-out duration-150"
                >
                  <svg
                    class="flex-shrink-0 h-6 w-6 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    ></path>
                  </svg>
                  <span> Sample Link</span>
                </a>
              </div> -->
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "DropDownMenuItem",
  data() {
    return {
      menuOpen: false
    };
  },
  props: ["label"]
};
</script>

<style></style>
