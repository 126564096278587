<template>
  <div>
    <HeaderLayout></HeaderLayout>

    <!--Container 3 -->

    <div style="clear: both; display: block; height: 0px;"></div>

    <!--Container - Popular Resorts -->
    <div class="w-full mx-auto pt-0 bg-white">
      <div
        class="flex w-full  bg-gradient-to-r from-gray-100 via-blue-100 to-green-100 text-left"
      >
        <h1
          class="text-2xl font-bold my-8 max-w-7xl md:mx-auto md:pl-4 mx-auto px-2 sm:px-6"
        >
          About
        </h1>
      </div>
      <div class="max-w-7xl mx-auto px-4 sm:px-6">
        <div class="flex flex-wrap items-center justify-center">
          <div class="mx-2 text-left">
            <p class="my-5 text-justify	">
              Maldives Holiday's is a travel & tours agency in Malé City,
              Maldives. It has been in the industry since 1993 and diversified
              to expand its presence in emerging markets and offer service
              online under the brand www.maldivesholidays.com. It is one of the
              longest-serving travel & tours agency in the Maldives, with the
              best trained and certified staff, to answer all your travel and
              tours inquires. Maldives Holidays is part of the Nalafalhu
              Holidays PVT. LTD
            </p>
            <p class="my-5 text-justify	">
              Maldives Holidays reflect the perfect blend of global expertise
              and local knowledge. Right from travel planning to organizing
              activities at Maldives and destinations around the world.
            </p>
            <p class="my-5 text-justify	">
              Our services include flight booking, Visa assistance, Travel
              Insurance, Hotel & Guest House bookings, Accommodation, Corporate
              & Private Tour Packages and Airport Services. We do customized
              travel solutions whether it’s for MNCs or SMEs in a hassle-free
              manner.
            </p>

            <p class="my-5 text-justify	">
              With thousands of satisfied customers, you can never go wrong with
              us. We are committed to establishing and maintaining the highest
              standard of integrity and customer service, and we are committed
              to putting first the needs of our clients.
            </p>
            <p class="my-5 mb-16">
              Try our services today and enjoy the professional, personal care
              and attention that our staff provides.
              <b>“Enjoy your holiday while we take care of everything.”</b>
            </p>
          </div>
        </div>
      </div>
    </div>

    <FooterLayout> </FooterLayout>
  </div>
</template>

<script>
import axios from "axios";
import HeaderLayout from "./PageLayouts/Header.Layout.vue";
import FooterLayout from "./PageLayouts/Footer.Layout.vue";

export default {
  name: "AboutPage",
  data() {
    return {
      properties: [],
      totalPages: "",
      currentPage: this.$route.query.page * 1 || 1
    };
  },
  mounted() {
    this.getProperties();
  },
  methods: {
    async getProperties() {
      try {
        const response = await axios.get(
          `${this.$customConfig.api_host}/api/v1/properties`
        );
        this.properties = response.data.properties;
        this.totalPages = response.data.totalPages;
        console.log("hello");
      } catch (e) {
        console.log(e);
      }
    }
  },
  nextPage() {
    if (this.canGoNextPage) {
      this.currentPage += 1;
      this.getProperties();
    }
  },
  previousPage() {
    if (this.canGoPreviousPage) {
      this.currentPage -= 1;
      this.getProperties();
    }
  },
  computed: {
    canGoNextPage() {
      return this.currentPage + 1 <= this.totalPages;
    },
    canGoPreviousPage() {
      return this.currentPage - 1 > 0;
    }
  },
  components: {
    HeaderLayout,
    FooterLayout
  }
};
</script>

<style scoped></style>
